exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-3-js": () => import("./../../../src/pages/about-3.js" /* webpackChunkName: "component---src-pages-about-3-js" */),
  "component---src-pages-build-facade-d-manually-js": () => import("./../../../src/pages/build-facade/d-manually.js" /* webpackChunkName: "component---src-pages-build-facade-d-manually-js" */),
  "component---src-pages-build-facade-draw-js": () => import("./../../../src/pages/build-facade/draw.js" /* webpackChunkName: "component---src-pages-build-facade-draw-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-details-js": () => import("./../../../src/pages/profile-details.js" /* webpackChunkName: "component---src-pages-profile-details-js" */),
  "component---src-pages-project-landing-js": () => import("./../../../src/pages/project-landing.js" /* webpackChunkName: "component---src-pages-project-landing-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-wind-log-js": () => import("./../../../src/pages/wind_log.js" /* webpackChunkName: "component---src-pages-wind-log-js" */),
  "component---src-pages-windinvoice-js": () => import("./../../../src/pages/windinvoice.js" /* webpackChunkName: "component---src-pages-windinvoice-js" */),
  "component---src-pages-windmaster-js": () => import("./../../../src/pages/windmaster.js" /* webpackChunkName: "component---src-pages-windmaster-js" */),
  "component---src-pages-windmaster-profile-js": () => import("./../../../src/pages/windmaster_profile.js" /* webpackChunkName: "component---src-pages-windmaster-profile-js" */),
  "component---src-templates-accept-facade-js": () => import("./../../../src/templates/accept-facade.js" /* webpackChunkName: "component---src-templates-accept-facade-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

