/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

// import 'mdbreact/dist/scss/mdb.scss';
import "animate.css"
import "./src/styles/css/boxicons.min.css"
import "./src/styles/css/flaticon.css"
import "react-accessible-accordion/dist/fancy-example.css"
import "/node_modules/aos/dist/aos.css"
import "swiper/css"
import "swiper/css/bundle"
import "bootstrap-icons/font/bootstrap-icons.css"
import "./src/styles/css/slick.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "./src/styles/css/bootstrap.min.css"

// Global style
import "./src/styles/css/style.scss"
import "./src/styles/css/responsive.css"
import "./src/styles/css/custom-style.css"
import "./src/styles/css/fonts.css"

import ReduxWrapper from "./src/components/_App/ReduxWrapper" // Import the ReduxWrapper component

import React from "react"
import { Provider } from "react-redux"
import { store } from "./src/components/Redux/Store"

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
)

export const onRouteUpdate = ({ location }) => {
  if (typeof window !== "undefined") {
    if (location.pathname !== "/xlsx/" || location.pathname !== "/dxf/") {
      localStorage.removeItem("allJobDataInStorage")
      localStorage.removeItem("jobId")
      localStorage.removeItem("jobCurrentState")
    }
  }
}

// If you want Multicolor comment out single
// import './src/styles/css/colors/brink-pink-style.css'
// import './src/styles/css/colors/pink-style.css'
// import './src/styles/css/colors/purple-style.css'
